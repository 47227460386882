import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RegisterService } from '../service/register.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: RegisterService) { }
  public basicAuth;
  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const token = localStorage.getItem('token');
    this.basicAuth = `Basic ` + btoa('upturn:DAF87DSFDSFDSA98FSADKJE324KJL32HFD7FDSFB24343J49DSF');
    const isLoggedIn = currentUser && token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (token) {
      request = request.clone({
        setHeaders: {
          authorization: this.basicAuth,
          token: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          authorization: this.basicAuth,
          'Content-Type': 'application/json',
        }
      });
    }

    return next.handle(request);
  }
}
