import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { OverlayModule } from '@angular/cdk/overlay';
import { ClickOutsideModule } from 'ng-click-outside';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './components/horizontal-menu/horizontal-menu.component';
import { ToastrModule } from "ngx-toastr";
import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from './pipes/pipe.module';
import { RouterModule } from '@angular/router';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { NumberValidationDirective } from './validation/number-validation.directive';
import { AutoFocus } from './directives/autofocus.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    CustomizerComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    ToggleFullscreenDirective,
    NumberValidationDirective,
    AutoFocus,

  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    ToastrModule,
    AutocompleteModule,
    PipeModule,
    NgxSpinnerModule,
    ImageCropperModule,
  ],
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    NgbModule,
    TranslateModule,
    CustomizerComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    ToastrModule,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    ToggleFullscreenDirective,
    NumberValidationDirective,
    AutoFocus,
    ImageCropperModule,

],
})
export class SharedModule { }
