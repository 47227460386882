import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  private baseUrl = environment.apiUrl + '/login'

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  postLogin(data: any) {
    return this._http.post<any>(`${this.baseUrl}`, data)
    .pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user.result && user?.result?.token) {
          user.result.userDetails['role'] = user?.result?.loginType;
          localStorage.setItem('currentUser', JSON.stringify(user?.result?.userDetails));
          localStorage.setItem('token', user?.result?.token);
          localStorage.setItem('loginType', user?.result?.loginType);
          localStorage.setItem('role', user?.result?.loginType);
          if(user?.result?.attendance?.checkInTime) {
            localStorage.setItem('121', user?.result?.attendance?.checkInTime)
          }
          setTimeout(() => {
            this._toastrService.success(
              'You have successfully logged in as an ' +
              user?.result?.role +
              ' user to Upturn. Now you can start to explore. Enjoy! 🎉',
              '👋 Welcome, ' +  user?.result?.userDetails?.empName + '!',
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 2500);
          // notify
          this.currentUserSubject.next(user?.result?.userDetails);
        }
        return user;
      })
    );
  }

    /**
   * User logout
   *
   */
     logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      localStorage.removeItem('11')
      localStorage.removeItem('token');
      localStorage.removeItem('loginType');
      localStorage.removeItem('menu');
      localStorage.removeItem('2');
      // notify
      this.currentUserSubject.next(null);
    }
}
