import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/helpers';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]
  },
  {
    path: 'event',
    loadChildren: () => import('../../event/event.module').then(m => m.EventModule), canActivate: [AuthGuard]
  },
  {
    path: 'website',
    loadChildren: () => import('../../website/service/website.module').then(m => m.WebsiteModule), canActivate: [AuthGuard]
  },
  {
    path: 'gallery',
    loadChildren: () => import('../../gallery/service/gallery.module').then(m => m.GalleryeModule), canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('../../register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard]
  },
  {
    path: 'asset', loadChildren: () => import('../../asset/asset.module').then(m => m.AssetModule), canActivate: [AuthGuard]
  },
  {
    path: 'expense',
    loadChildren: () => import('../../expense/expense.module').then(m => m.ExpenseModule), canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('../../inventory/inventory.module').then(m => m.InventoryModule), canActivate: [AuthGuard]
  },
  {
    path: 'area',
    loadChildren: () => import('../../area/area.module').then(m => m.AreaModule), canActivate: [AuthGuard]
  },
  {
    path: 'enquiry2',
    loadChildren: () => import('../../enquiry/enquiry.module').then(m => m.EnquiryModule), canActivate: [AuthGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('../../report/report.module').then(m => m.ReportModule), canActivate: [AuthGuard]
  },
  {
    path: 'registration2',
    loadChildren: () => import('../../register/register.module').then(m => m.RegisterModule), canActivate: [AuthGuard]
  },

];
